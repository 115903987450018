<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <v-card>
      <v-card-title>
        Nuevo rol
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="$store.state.loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="$store.state.loading"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      nombre: null
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        this.nombre = this.nombre.toUpperCase()
        await this.$store.dispatch('roles/nuevo_rol', this.nombre)
          .then((res) => {
            this.dialog = false
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
            this.$emit('nuevo', {
              id: res.id,
              nombre: this.nombre
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    clear () {
      this.nombre = null
      this.$refs.form.resetValidation()
    }
  }
}
</script>