<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="funcion = null; dialog_funcion = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  Descripcion
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="5" xl="4">
                  <v-row style="width: 100%" :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="6" class="py-1">
                      Rol
                      <v-autocomplete
                        v-model="filtro.rol"
                        item-value="nombre"
                        item-text="nombre"
                        :items="roles"
                        hide-details
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-1">
                      Usuario
                      <v-autocomplete
                        v-model="filtro.usuario"
                        :items="usuarios"
                        hide-details
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5" sm="3" md="1" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="funciones"
        :loading="load"
        dense
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitado"
            color="indigo"
            title="Editar"
            class="mr-2"
            small
            @click="funcion = item; dialog_funcion = true"
          >
            fas fa-pen
          </v-icon>
          <v-icon
            v-if="!item.inhabilitado"
            color="purple"
            title="Roles"
            class="mr-2"
            small
            @click="get_roles(item)"
          >
            fas fa-tag
          </v-icon>
          <v-icon
            v-if="!item.inhabilitado"
            color="lime"
            title="Usuarios"
            class="mr-2"
            small
            @click="get_usuarios(item)"
          >
            fas fa-user
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar la función <strong>${item.descripcion}</strong>?`
              : `¿Desea inhabilitar la función <strong>${item.descripcion}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Funcion
      v-model="dialog_funcion"
      :p_funcion="funcion"
      @editar="editar_funcion"
    />
    <FuncionRoles
      v-model="dialog_roles"
      :p_funcion="funcion"
    />
    <FuncionUsuarios
      v-model="dialog_usuarios"
      :p_funcion="funcion"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import BtnFiltro from '../../components/util/BtnFiltro'
import Funcion from '../../components/admin/Funcion'
import FuncionRoles from '../../components/admin/FuncionRoles'
import FuncionUsuarios from '../../components/admin/FuncionUsuarios'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog_funcion: false,
      dialog_roles: false,
      dialog_usuarios: false,
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      funciones: [],
      funcion: {},
      filtro: {
        descripcion: '',
        rol: '',
        usuario: '',
        inhabilitado: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('usuarios/get_roles')
    await this.$store.dispatch('funciones/get_usernames')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    ...mapState('funciones', ['usuarios'])
  },
  components: {
    BtnConfirmar,
    BtnFiltro,
    Funcion,
    FuncionRoles,
    FuncionUsuarios
  },
  methods: {
    async get_roles (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('funciones/get_roles', item.id)
        .then((res) => {
          item.roles = res.data
          this.funcion = item
          this.dialog_roles = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_usuarios (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('funciones/get_usuarios', item.id)
        .then((res) => {
          item.usuarios = res.data
          this.funcion = item
          this.dialog_usuarios = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('funciones/inhabilitar', item.id)
        .then((res) => {
          this.quitar_funcion(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('funciones/habilitar', item.id)
        .then((res) => {
          this.quitar_funcion(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.funciones = []
      this.load = true
      await this.$store.dispatch('funciones/get_funciones', this.filtro)
        .then((res) => {
          this.funciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar_funcion (item) {
      let funcion = this.funciones.find(fun => fun.id == item.id)
      Object.assign(funcion, item)
    },
    quitar_funcion (item) {
      const index = this.funciones.indexOf(item)
      this.funciones.splice(index, 1)
    },
    limpiar () {
      this.filtro = {
        descripcion: '',
        rol: '',
        usuario: '',
        inhabilitado: 0
      }
    }
  }
}
</script>