<template>
  <v-dialog
    v-model="dialog"
    width="450"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Empresas de {{ p_usuario ? p_usuario.username : '' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="usuario_empresas"
                label="Empresas"
                item-value="id"
                item-text="nombre"
                :items="$store.state.empresas"
                :rules="[$store.state.rules.requiredArray]"
                validate-on-blur
                deletable-chips
                small-chips
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      usuario_empresas: []
    }
  },
  props: {
    value: Boolean,
    p_usuario: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        this.usuario_empresas = JSON.parse(JSON.stringify(this.p_usuario.empresas))
      } else {
        this.$refs.form.resetValidation()
        this.usuario_empresas = []
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // verifico si realmente modifico algo
        if (JSON.stringify(this.usuario_empresas) != JSON.stringify(this.p_usuario.empresas)) {
          this.load = true
          let result = await this.$store.dispatch('usuarios/editar_empresas', {
            id: this.p_usuario.id,
            empresas: this.usuario_empresas
          })
          this.load = false

          if (result.exito == 1) {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }
        } else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en las empresas del usuario',
            color: 'warning'
          })
        }
      }
    }
  }
}
</script>