<template>
  <v-dialog
    v-model="dialog"
    width="700"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nuevo ? 'Nuevo usuario' : 'Editar usuario' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="usuario.nombre"
                label="Nombre"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="usuario.apellido"
                label="Apellido"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="usuario.username"
                label="Usuario"
                :readonly="!nuevo"
                :filled="!nuevo"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                v-model.trim="usuario.email"
                label="Email"
                :readonly="!nuevo"
                :filled="!nuevo"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="usuario.Cuil"
                label="Cuil"
                :readonly="!nuevo"
                :filled="!nuevo"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <template v-if="nuevo">
              <v-col cols="12" md="4" class="py-0">
                <v-text-field
                  v-model.trim="usuario.clave"
                  label="Clave"
                  :rules="[$store.state.rules.required]"
                  hint="Mínimo 6 caracteres"
                  validate-on-blur
                  outlined
                  counter
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="7" class="py-0">
                <v-autocomplete
                  v-model="usuario.roles"
                  label="Roles"
                  item-value="id"
                  item-text="nombre"
                  :items="roles"
                  :rules="[$store.state.rules.requiredArray]"
                  validate-on-blur
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-autocomplete
                  v-model="usuario.empresas"
                  label="Empresas"
                  item-value="id"
                  item-text="nombre"
                  :items="$store.state.empresas"
                  :rules="[$store.state.rules.requiredArray]"
                  validate-on-blur
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="$store.state.loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="$store.state.loading"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      nuevo: false,
      usuario: {}
    }
  },
  props: {
    value: Boolean,
    p_usuario: Object
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_usuario) {
          this.nuevo = false
          this.usuario = JSON.parse(JSON.stringify(this.p_usuario))
        } else {
          this.nuevo = true
          this.usuario = {
            username: null,
            nombre: null,
            apellido: null,
            email: null,
            roles: [],
            empresas: [],
            Cuil: null
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        if (this.nuevo) {
          this.usuario.username = this.usuario.username.toUpperCase()
          await this.$store.dispatch('usuarios/nuevo_usuario', this.usuario)
            .then((res) => {
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.usuario.id = res.id
              this.$emit('nuevo', this.usuario)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // verifica si realmente modifico algo
          if (JSON.stringify(this.usuario) != JSON.stringify(this.p_usuario)) {
            await this.$store.dispatch('usuarios/editar_usuario', this.usuario)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.$emit('editar', this.usuario)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el usuario',
              color: 'warning'
            })
          }
        }
        this.$store.state.loading = false
      }
    }
  }
}
</script>