<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="menu = null; dialog_menu = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" xl="2" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  Rol
                  <v-autocomplete
                    v-model="filtro.rol"
                    item-value="id"
                    item-text="nombre"
                    :items="roles"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="5" sm="2" xl="1" class="py-1">
                  Padre id
                  <v-text-field
                    v-model.trim="filtro.padre"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="1" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="menues"
        :loading="load"
        :search="search"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.icono`]="{ item }">
          <v-icon small>{{ item.icono }}</v-icon>
        </template>
        <template v-slot:[`item.tiene_instructivo`]="{ item }">
          <v-icon
            v-if="item.tiene_instructivo == 1"
            color="success"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitado"
            color="indigo"
            title="Editar"
            class="mr-2"
            small
            @click="menu = item; dialog_menu = true"
          >
            fas fa-pen
          </v-icon>
          <v-icon
            v-if="!item.inhabilitado"
            color="purple"
            title="Roles"
            class="mr-2"
            small
            @click="get_roles(item)"
          >
            fas fa-user-tag
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar el menu <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar el menu <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Menu
      v-model="dialog_menu"
      :p_menu="menu"
      @editar="editar_menu"
    />
    <MenuRoles
      v-model="dialog_roles"
      :p_menu="menu"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import SearchDataTable from '../../components/util/SearchDataTable'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import BtnFiltro from '../../components/util/BtnFiltro'
import Menu from '../../components/admin/Menu'
import MenuRoles from '../../components/admin/MenuRoles'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog_menu: false,
      dialog_roles: false,
      search: '',
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Url', value: 'url' },
        { text: 'Icono', value: 'icono', align: 'center', sortable: false, filterable: false },
        { text: 'Padre id', value: 'padre', align: 'center' },
        { text: 'Instructivo', value: 'tiene_instructivo', align: 'center' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      menues: [],
      menu: {},
      filtro: {
        nombre: '',
        rol: null,
        padre: null,
        inhabilitado: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('usuarios/get_roles')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('usuarios', ['roles'])
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    BtnFiltro,
    Menu,
    MenuRoles
  },
  methods: {
    async get_roles (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('menu_lateral/get_roles_menu', item.id)
        .then((res) => {
          item.roles = res.data
          this.menu = item
          this.dialog_roles = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('menu_lateral/inhabilitar_menu', item.id)
        .then((res) => {
          this.quitar_menu(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('menu_lateral/habilitar_menu', item.id)
        .then((res) => {
          this.quitar_menu(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.menues = []
      this.search = ''
      this.load = true
      await this.$store.dispatch('menu_lateral/get_menu', this.filtro)
        .then((res) => {
          this.menues = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar_menu (item) {
      let menu = this.menues.find(men => men.id == item.id)
      Object.assign(menu, item)
    },
    quitar_menu (item) {
      const index = this.menues.indexOf(item)
      this.menues.splice(index, 1)
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        rol: null,
        padre: null,
        inhabilitado: 0
      }
    }
  }
}
</script>