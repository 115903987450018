<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="ruta = null; dialog_ruta = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" xl="2" class="py-1">
                  Ruta
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" xl="2" class="py-1">
                  Descripcion
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  Rol
                  <v-autocomplete
                    v-model="filtro.rol"
                    item-value="id"
                    item-text="nombre"
                    :items="roles"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="rutas"
        :loading="load"
        dense
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="indigo"
            title="Editar"
            class="mr-2"
            small
            @click="ruta = item; dialog_ruta = true"
          >
            fas fa-pen
          </v-icon>
          <v-icon
            color="purple"
            title="Roles"
            class="mr-2"
            small
            @click="get_roles(item)"
          >
            fas fa-user-tag
          </v-icon>
          <BtnConfirmar
            icono="fas fa-trash"
            color="error"
            title="Eliminar"
            :texto="`<strong>¿Desea eliminar la ruta '${item.nombre}' ?</strong>**<br/><br/>** ésta acción no se puede deshacer y los menu que utilicen la ruta ya no podran acceder a la misma`"
            :icon_button="true"
            :small="true"
            @action="eliminar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Ruta
      v-model="dialog_ruta"
      :p_ruta="ruta"
      @editar="editar_ruta"
    />
    <RutaRoles
      v-model="dialog_roles"
      :p_ruta="ruta"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import BtnFiltro from '../../components/util/BtnFiltro'
import Ruta from '../../components/admin/Ruta'
import RutaRoles from '../../components/admin/RutaRoles'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog_ruta: false,
      dialog_roles: false,
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Ruta', value: 'nombre' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      rutas: [],
      ruta: {},
      filtro: {
        nombre: '',
        descripcion: '',
        rol: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('usuarios/get_roles')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('usuarios', ['roles'])
  },
  components: {
    BtnConfirmar,
    BtnFiltro,
    Ruta,
    RutaRoles
  },
  methods: {
    async get_roles (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('rutas/get_roles', item.id)
        .then((res) => {
          item.roles = res.data
          this.ruta = item
          this.dialog_roles = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async eliminar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('rutas/eliminar', item.id)
        .then((res) => {
          const index = this.rutas.indexOf(item)
          this.rutas.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.rutas = []
      this.load = true
      await this.$store.dispatch('rutas/get_rutas', this.filtro)
        .then((res) => {
          this.rutas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar_ruta (item) {
      let ruta = this.rutas.find(rut => rut.id == item.id)
      Object.assign(ruta, item)
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        descripcion: '',
        rol: null
      }
    }
  }
}
</script>