<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="dialog = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" xl="2" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="3" md="2" xl="1" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="roles"
        :loading="load"
        dense
      >
        <template v-slot:[`item.actions`]="{ item }">
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar el rol <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar el rol <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Rol
      v-model="dialog"
    />
  </v-row>
</template>

<script>
import BtnConfirmar from '../../components/util/BtnConfirmar'
import BtnFiltro from '../../components/util/BtnFiltro'
import Rol from '../../components/admin/Rol'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      roles: [],
      filtro: {
        nombre: '',
        inhabilitado: 0
      }
    }
  },
  components: {
    BtnConfirmar,
    BtnFiltro,
    Rol
  },
  methods: {
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('roles/inhabilitar_rol', { id: item.id })
        .then((res) => {
          this.quitar_rol(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('roles/habilitar_rol', { id: item.id })
        .then((res) => {
          this.quitar_rol(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.roles = []
      this.load = true
      await this.$store.dispatch('roles/get_roles', this.filtro)
        .then((res) => {
          this.roles = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    quitar_rol (item) {
      const index = this.roles.indexOf(item)
      this.roles.splice(index, 1)
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        inhabilitado: 0
      }
    }
  }
}
</script>