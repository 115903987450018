<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nueva ? 'Nueva función' : 'Editar función' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="funcion.descripcion"
                label="Descripción"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="nueva"
              cols="12" sm="6" class="py-0"
            >
              <v-autocomplete
                v-model="funcion.roles"
                label="Roles"
                item-value="nombre"
                item-text="nombre"
                :items="roles"
                deletable-chips
                small-chips
                clearable
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="nueva"
              cols="12" sm="6" class="py-0"
            >
              <v-autocomplete
                v-model="funcion.usuarios"
                label="Usuarios"
                :items="usuarios"
                deletable-chips
                small-chips
                clearable
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="$store.state.loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="$store.state.loading"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      nueva: false,
      funcion: {}
    }
  },
  props: {
    value: Boolean,
    p_funcion: Object
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    ...mapState('funciones', ['usuarios']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_funcion) {
          this.nueva = false
          this.funcion = JSON.parse(JSON.stringify(this.p_funcion))
        } else {
          this.nueva = true
          this.funcion = {
            descripcion: null,
            roles: [],
            usuarios: []
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        if (this.nueva) {
          await this.$store.dispatch('funciones/nueva', this.funcion)
            .then((res) => {
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.funcion.id = res.id
              this.funcion.descripcion = this.funcion.descripcion.toUpperCase()
              this.$emit('nueva', this.funcion)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // verifica si realmente modifico algo
          if (JSON.stringify(this.funcion) != JSON.stringify(this.p_funcion)) {
            await this.$store.dispatch('funciones/editar', this.funcion)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.funcion.descripcion = this.funcion.descripcion.toUpperCase()
                this.$emit('editar', this.funcion)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la funcion',
              color: 'warning'
            })
          }
        }
        this.$store.state.loading = false
      }
    }
  }
}
</script>