<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nueva ? 'Nueva ruta' : 'Editar ruta' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="ruta.nombre"
                label="Nombre"
                :hint="!nueva ? '**' : ''"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="nueva"
              cols="12" sm="8" class="py-0"
            >
              <v-autocomplete
                v-model="ruta.roles"
                label="Roles"
                item-value="id"
                item-text="nombre"
                :items="roles"
                :rules="[$store.state.rules.requiredArray]"
                validate-on-blur
                deletable-chips
                small-chips
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="8" class="py-0">
              <v-text-field
                v-model.trim="ruta.descripcion"
                label="Descripción"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        {{ !nueva ? '** si se cambia el nombre de la ruta y ésta también se utiliza en un menu, es necesario modificar el nombre de la Url del menu' : '' }}
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="$store.state.loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="$store.state.loading"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      nueva: false,
      ruta: {}
    }
  },
  props: {
    value: Boolean,
    p_ruta: Object
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_ruta) {
          this.nueva = false
          this.ruta = JSON.parse(JSON.stringify(this.p_ruta))
        } else {
          this.nueva = true
          this.ruta = {
            nombre: null,
            descripcion: null,
            roles: []
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        if (this.nueva) {
          await this.$store.dispatch('rutas/nueva', this.ruta)
            .then((res) => {
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.ruta.id = res.id
              this.$emit('nueva', this.ruta)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // verifica si realmente modifico algo
          if (JSON.stringify(this.ruta) != JSON.stringify(this.p_ruta)) {
            await this.$store.dispatch('rutas/editar', this.ruta)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.$emit('editar', this.ruta)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la ruta',
              color: 'warning'
            })
          }
        }
        this.$store.state.loading = false
      }
    }
  }
}
</script>