<template>
  <v-text-field
    v-model="search"
    label="Buscar"
    hide-details
    single-line
    outlined
    dense
  >
    <template v-slot:append>
      <v-icon
        class="mt-1"
        title="Buscar en los datos filtrados"
        small
      >
        fas fa-search
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
/**
 * Barra de búsqueda interna para data tables
 */
export default {
  props: {
    value: String // texto a buscar en el data table
  },
  computed: {
    search: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>