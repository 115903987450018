<template>
  <v-dialog
    v-model="dialog"
    width="500"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Roles de la ruta {{ p_ruta ? p_ruta.nombre : '' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="ruta_roles"
                label="Roles"
                item-value="id"
                item-text="nombre"
                :items="roles"
                :rules="[$store.state.rules.requiredArray]"
                validate-on-blur
                deletable-chips
                small-chips
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      ruta_roles: []
    }
  },
  props: {
    value: Boolean,
    p_ruta: Object
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        this.ruta_roles = JSON.parse(JSON.stringify(this.p_ruta.roles))
      } else {
        this.$refs.form.resetValidation()
        this.ruta_roles = []
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // verifica si realmente modifico algo
        if (JSON.stringify(this.ruta_roles) != JSON.stringify(this.p_ruta.roles)) {
          this.load = true
          await this.$store.dispatch('rutas/editar_roles', {
            id: this.p_ruta.id,
            roles: this.ruta_roles
          })
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        } else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en los roles de la ruta',
            color: 'warning'
          })
        }
      }
    }
  }
}
</script>