<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn style="position: absolute; top: -15px; right: 55px; z-index: 1" color="success" small fab
            @click="usuario = null; dialog_usuario = true">
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Usuario
                  <v-text-field v-model.trim="filtro.username" tabindex="1" hide-details autofocus outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="3" class="py-1">
                  Email
                  <v-text-field v-model.trim="filtro.email" tabindex="1" hide-details outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="3" xl="2" class="py-1">
                  Rol
                  <v-autocomplete v-model="filtro.rol" item-value="id" item-text="nombre" :items="roles" hide-details
                    clearable outlined dense></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5" md="2" class="py-1">
                  Empresa
                  <v-autocomplete v-model="filtro.empresa" item-value="id" item-text="nombre"
                    :items="$store.state.empresas" hide-details clearable outlined dense></v-autocomplete>
                </v-col>
                <v-col cols="5" sm="3" md="1" class="d-flex justify-center align-center py-1">
                  <v-switch v-model="filtro.inhabilitado" label="Inhabilitado" class="mb-md-2" tabindex="1"
                    :true-value="1" :false-value="0" hide-details dense></v-switch>
                </v-col>
                <BtnFiltro :loading="load" @clear="limpiar()" />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table class="cebra elevation-2 mt-2" sort-by="username" :headers="headers" :items="usuarios"
        :loading="load" :search="search" dense>
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable v-model="search" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.incidentes`]="{ item }">
          <v-icon v-if="item.Personal_id" color="success" title="Habilitado para cargar incidentes" class="" small>
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!item.inhabilitado" color="indigo" title="Editar" class="mr-2" small
            @click="usuario = item; dialog_usuario = true">
            fas fa-pen
          </v-icon>
          <v-icon v-if="!item.inhabilitado" color="purple" title="Roles" class="mr-2" small @click="get_roles(item)">
            fas fa-user-tag
          </v-icon>
          <v-icon v-if="!item.inhabilitado" color="teal" title="Empresas" class="mr-2" small
            @click="get_empresas(item)">
            fas fa-building
          </v-icon>

          <BtnConfirmar :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'" :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar al usuario <strong>${item.username}</strong>?`
              : `¿Desea inhabilitar al usuario <strong>${item.username}</strong>?`" :icon_button="true" :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)" />
          <v-btn icon v-if="!item.Personal_id" :disabled="item.inhabilitado == 1" @click="validarAlta(item)"
            color="success" title="Validar alta" small>
            <v-icon small>fas fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-alert class="mx-auto mt-4" max-width="400" type="warning" border="left" dense text>
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Usuario v-model="dialog_usuario" :p_usuario="usuario" @editar="editar_usuario" />
    <UsuarioRoles v-model="dialog_roles" :p_usuario="usuario" />
    <UsuarioEmpresas v-model="dialog_empresas" :p_usuario="usuario" />
    <UsuarioCuil v-model="dialog_cuil" :usuario="usuario" />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import SearchDataTable from '../../components/util/SearchDataTable'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import BtnFiltro from '../../components/util/BtnFiltro'
import Usuario from '../../components/admin/Usuario'
import UsuarioRoles from '../../components/admin/UsuarioRoles'
import UsuarioEmpresas from '../../components/admin/UsuarioEmpresas.vue'
import UsuarioCuil from '../../components/admin/UsuarioCuil.vue'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      dialog_roles: false,
      dialog_usuario: false,
      dialog_empresas: false,
      dialog_cuil: false,
      search: '',
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Usuario', value: 'username' },
        { text: 'Nombre', value: 'nombre_completo' },
        { text: 'Email', value: 'email' },
        { text: 'Incidentes', value: 'incidentes' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      usuarios: [],
      usuario: {},
      filtro: {
        username: '',
        email: '',
        rol: null,
        empresa: null,
        inhabilitado: 0
      }
    }
  },
  async created() {
    this.$store.state.loading = true
    await this.$store.dispatch('get_jarvis_token')
    await this.$store.dispatch('usuarios/get_roles')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('usuarios', ['roles'])
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    BtnFiltro,
    Usuario,
    UsuarioRoles,
    UsuarioEmpresas,
    UsuarioCuil
  },
  methods: {
    async get_empresas(item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('usuarios/get_empresas_usuario', item.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        item.empresas = result.data
        this.usuario = item
        this.dialog_empresas = true
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async get_roles(item) {
      this.$store.state.loading = true
      await this.$store.dispatch('usuarios/get_roles_usuario', item.id)
        .then((res) => {
          item.roles = res.data
          this.usuario = item
          this.dialog_roles = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar(item) {
      this.$store.state.loading = true
      await this.$store.dispatch('usuarios/inhabilitar_usuario', {
        id: item.id,
        uid: item.username
      })
        .then((res) => {
          this.quitar_usuario(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async habilitar(item) {
      this.$store.state.loading = true
      await this.$store.dispatch('usuarios/habilitar_usuario', {
        id: item.id,
        uid: item.username
      })
        .then((res) => {
          this.quitar_usuario(item)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar() {
      this.usuarios = []
      this.search = ''
      this.load = true
      await this.$store.dispatch('usuarios/get_usuarios', this.filtro)
        .then((res) => {
          this.usuarios = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar_usuario(item) {
      let usuario = this.usuarios.find(us => us.id == item.id)
      item.nombre_completo = item.nombre + ' ' + item.apellido
      Object.assign(usuario, item)
    },
    quitar_usuario(item) {
      const index = this.usuarios.indexOf(item)
      this.usuarios.splice(index, 1)
    },

    async validarAlta(usuario) {
      if (!usuario.Cuil) {
        this.dialog_cuil = true
        this.usuario = usuario
      } else {
        await this.$store.dispatch('usuarios/altaJarvis', {
          usuario_id: usuario.id,
          cuil: usuario.Cuil,
          nombre: usuario.nombre,
          apellido: usuario.apellido
        }).then(res => {
          if (res.exito) {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      }
    },

    limpiar() {
      this.filtro = {
        username: '',
        email: '',
        rol: null,
        empresa: null,
        inhabilitado: 0
      }
    }
  }
}
</script>