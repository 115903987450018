<template>
  <v-dialog
    v-model="dialog"
    width="750"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nuevo ? 'Nuevo menu' : 'Editar menu' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="menu.nombre"
                label="Nombre"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="menu.url"
                label="Url"
                hint="**"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model.trim="menu.icono"
                label="Icono"
                :append-icon="menu.icono"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model.trim="menu.padre"
                label="Padre id"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model.trim="menu.orden"
                label="Orden"
                type="number"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="nuevo"
              cols="12" sm="8" class="py-0"
            >
              <v-autocomplete
                v-model="menu.roles"
                label="Roles"
                item-value="id"
                item-text="nombre"
                :items="roles"
                :rules="[$store.state.rules.requiredArray]"
                validate-on-blur
                deletable-chips
                small-chips
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="!nuevo"
              cols="12" sm="8" class="py-0"
            >
              <v-text-field
                v-model.trim="menu.instructivo"
                label="Url instructivo"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        {{ nuevo ?
          '** al crear un menu con Url tambien es necesario crear la ruta correspondiente con el mismo nombre que la Url' :
          '** al modificar la Url tambien es necesario modificar el nombre de la ruta correspondiente'
        }}
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="$store.state.loading"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="$store.state.loading"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      nuevo: false,
      menu: {}
    }
  },
  props: {
    value: Boolean,
    p_menu: Object
  },
  computed: {
    ...mapState('usuarios', ['roles']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_menu) {
          this.nuevo = false
          this.menu = JSON.parse(JSON.stringify(this.p_menu))
        } else {
          this.nuevo = true
          this.menu = {
            nombre: null,
            url: null,
            icono: null,
            padre: null,
            orden: null,
            roles: []
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        if (this.nuevo) {
          await this.$store.dispatch('menu_lateral/nuevo_menu', this.menu)
            .then((res) => {
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.menu.id = res.id
              this.$emit('nuevo', this.menu)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // verifica si realmente modifico algo
          if (JSON.stringify(this.menu) != JSON.stringify(this.p_menu)) {
            await this.$store.dispatch('menu_lateral/editar_menu', this.menu)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.menu.tiene_instructivo = this.menu.instructivo ? 1 : 0
                this.$emit('editar', this.menu)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el menu',
              color: 'warning'
            })
          }
        }
        this.$store.state.loading = false
      }
    }
  }
}
</script>